import React from "react"
import { StyledContentArea, StyledContentAreaContainer, StyledFeaturedImage } from "./styles"

import { StyledModuleContainer } from '@styles/global-components/_containers';

export default function ContentWithImage({
  module,
  isBlogPage
}) {
  module = module.contentWithImage ? module.contentWithImage : module.contentWithImageBlog;

  if(module) return (
    <StyledModuleContainer
      $sectionOptions={module.sectionOptions}
      moduleSpacing={(module.spacingOptions && module.spacingOptions.enableSmallModuleSpacing) ? 'xs' : 'md'}
      id={module.sectionOptions && module.sectionOptions.sectionId}
      data-name="content-with-image"
  >
    <StyledContentAreaContainer 
      type={
          (module.spacingOptions && module.spacingOptions.enableInnerMargin) || isBlogPage ? 'inner' : ''
      }
      $imageDisplay={module.imageDisplay}
    >

        {module.contentArea && (
          <StyledContentArea 
            $display={module.display} 
            content={module.contentArea} 
            $imageDisplay={module.imageDisplay}
            $textDisplay={module.textDisplay}
            fontOverrides={module.fontOptions && module.fontOptions.enableFontOverrides && module.fontOptions}
          />
        )}

        {module.featuredImage && 
            <StyledFeaturedImage 
                alt={module.featuredImage.altText}
                imageFile={module.featuredImage.imageFile}
                $display={module.display}
                $imageDisplay={module.imageDisplay}
            />
        }

      </StyledContentAreaContainer>
    </StyledModuleContainer>
  )
  return null;
}
