import styled, { css } from 'styled-components';
import { theme } from '@theme';
import WysiwygContent from '../../commons/wysiwyg-content';

import { StyledContainer } from '@styles/global-components/_containers';

import WrImage from '@commons/wr-image'

export const StyledContentAreaContainer = styled(StyledContainer)`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column-reverse;

    ${theme.mediaQuerys.smUp} {
        flex-direction: ${
            props => props.$imageDisplay === 'image-left' ? 
            'row-reverse' : 'row'};
    }
`

export const StyledContentArea = styled(WysiwygContent)`
    width: 100%;
    margin-top: 25px;

    ${theme.mediaQuerys.smUp} {
        margin-top: 0;
    }

    ${props => props.$display === 'two-column' && css`

        ${theme.mediaQuerys.smUp} {
            width: 60%;
        }

        ${theme.mediaQuerys.lgUp} {
            width: 62.5%;
        }
    `}

    ${props => props.$display === 'image-one-third' && css`

        ${theme.mediaQuerys.smUp} {
            width: 60%;
        }

        ${theme.mediaQuerys.mdUp} {
            width: 70%;
        }
    `}

    ${props => props.$imageDisplay === 'image-left' ? css`
        
        ${theme.mediaQuerys.smUp} {
            padding-left: 10%;
        }
    `:css`
    
        ${theme.mediaQuerys.smUp} {
            padding-right: 10%;
        }
    `}

    ${props => props.$textDisplay === 'text-right' ? css`
        
        ${theme.mediaQuerys.smUp} {
            text-align: right;
        }
    `:css`
    
        ${theme.mediaQuerys.smUp} {
            text-align: left;
        }
    `}
`

export const StyledFeaturedImage = styled(WrImage)`
    width: 75%;

    ${props => props.$display === 'two-column' && css`

        ${theme.mediaQuerys.smUp} {
            width: 40%;
        }

        ${theme.mediaQuerys.lgUp} {
            width: 37.5%;
        }
    `}

    ${props => props.$display === 'image-one-third' && css`

        ${theme.mediaQuerys.smUp} {
            width: 40%;
        }

        ${theme.mediaQuerys.mdUp} {
            width: 30%;
        }
    `}
`